// Funciones
var vm = '';

function onInitialize()
{
    // Select2
    $('#sl-sede').select2({
        allowClear: false,
        language: 'es',
        ajax: {
            url: 'ws/sedes/search',
            dataType: 'json',
            delay: 250,
            data: function(params) {
                return {
                    search: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                return {
                    results: data.results,
                    pagination: {
                        more: data.metadata.resultset.count >= 10
                    }
                }
            },
            cache: true
        },
        minimumInputLength: 1,
        templateResult: templateResultSede,
        templateSelection: templateSelectionSede
    });
    
    $('#sl-area').select2({
        allowClear: false,
        language: 'es',
        ajax: {
            url: 'ws/areas/search',
            dataType: 'json',
            delay: 250,
            data: function(params) {
                return {
                    search: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                return {
                    results: data.results,
                    pagination: {
                        more: data.metadata.resultset.count >= 10
                    }
                }
            },
            cache: true
        },
        minimumInputLength: 1,
        templateResult: templateResultArea,
        templateSelection: templateSelectionArea
    });
    
    // Vue
    vm = new Vue({
        el: 'body',
        data: {
            'user': '',
            'buscando': false
        },
        methods: {
            'searchUser': function() {
                vm.user = '';
                vm.buscando = true;
                var url = 'ws/users/search?search=' + $('#txt-username').val();
                
                $.getJSON(url, [], function(item) {
                    vm.user = item;
                    vm.buscando = false;
                });
            },
            'cleanSearch': function() {
                vm.user = '';
            }
        }
    });
    
    
}

// Templates Select2
function templateResultSede(item) {
    if (!item.id) return item.text;
    
    return $('<span><strong>' + item.siglas + '</strong> ' + item.nombre + '<br /><small>' + item.geolocalizacion.calle + ' ' + item.geolocalizacion.numero + ', ' + item.geolocalizacion.ciudad + ', ' + item.geolocalizacion.provincia + '</small></span>');
}

function templateSelectionSede(item) {
    return (! item.nombre) ? item.text : '[' + item.siglas + '] ' + item.nombre;
}

function templateResultArea(item) {
    if (!item.id) return item.text;
    
    return $('<span><strong>' + item.nombre + '</strong><br /><small>' + item.parent.nombre + '</small></span>');
}

function templateSelectionArea(item) {
    return (! item.nombre) ? item.text : item.nombre;
}

// Interacciones
$(document).on('ready', onInitialize);
